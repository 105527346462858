import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router'
import { useCookies } from 'vue3-cookies'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import { decipherJWT } from '@egalteam/framework'
import { useStore } from '@/store'
import { ref } from 'vue'
import { clearCookies } from '@/helpers/scripts'

const { cookies } = useCookies()
const { getRoles, getCoreCookie } = useJWTDecode()
const roles = ref(getRoles())

const isAdmin = ref(roles?.value?.includes(Roles.Admin))

const routes: Array<RouteRecordRaw> = [
  {
    path: '/1c-table',
    name: '1C table',
    component: () => import('../views/1C/views/1CTable/1CTable.vue'),
    props: true,
  },
  {
    path: '/1c-faq',
    name: '1C FAQ',
    component: () => import('../views/1C/views/1CFAQ/1CFAQ.vue'),
    props: true,
  },
  {
    path: '/chat/:id',
    name: 'Конкретный чат',
    component: () => import('../views/ChatView.vue'),
  },
  {
    path: '/chat',
    name: 'Чат',
    component: () => import('../views/ChatView.vue'),
  },
  {
    path: '/',
    name: 'Главная',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/sign-in',
    name: 'Авторизация',
    component: () => import('../views/LoginView.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/register',
    name: 'Регистрация',
    component: () => import('../views/Register/RegisterView.vue'),
    meta: {
      layout: 'auth',
    },
  },

  {
    path: '/invite-register',
    name: 'Регистрация по инвайту',
    component: () => import('../views/InviteRegister/InviteRegisterView.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/register-documents',
    name: 'Регистрация Документов',
    component: () => import('../views/DocumentStep.vue'),
  },
  {
    path: '/recovery',
    name: 'Сброс пароля',
    component: () => import('../views/RecoveryView.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/confirm-email',
    name: 'Подтверждение почты',
    component: () => import('../views/Register/ConfirmEmailView.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/products',
    name: 'Мои товары',
    component: () => import('../views/Farmer/FarmerHome.vue'),
  },

  {
    path: '/edit-product/:id',
    name: 'Редактирование товара',
    component: () => import('../views/Farmer/EditProductView.vue'),
  },
  {
    path: '/add-product',
    name: 'Добавление товара',
    component: () => import('../views/Farmer/AddProductView.vue'),
  },
  {
    path: '/edit-special-prices/:id',
    name: 'Редактирование спеццен',
    component: () => import('../views/Farmer/EditSpecialPrices.vue'),
  },
  {
    path: '/edit-price-options/:id',
    name: 'Редактирование списка цен',
    component: () => import('../views/Farmer/EditPriceOptions.vue'),
  },
  {
    path: '/cart',
    name: 'Корзина',
    component: () => import('../views/Customer/CartView.vue'),
  },
  {
    path: '/verification',
    name: 'Верификация',
    component: () => import('../views/VerificationView.vue'),
  },

  //  TODO переименовать роуты для профиля покупателя и поставщика тут и везде
  {
    // История заказов для покупателя
    path: '/profile',
    name: 'Покупатель',
    component: () => import('../views/Customer/CustomerHistory.vue'),
  },
  {
    // История заказов для поставщика
    path: '/home',
    name: 'Поставщик',
    component: () => import('../views/Farmer/FarmerHistory.vue'),
  },
  {
    // История заказов
    path: '/history',
    name: 'История заказов',
    component: () => import('../views/ProfileView.vue'),
  },
  {
    // Каталог товаров
    path: '/products-catalog',
    name: 'products-catalog',
    component: () => import('../views/ProductsCatalog.vue'),
    props: true,
  },
  {
    // История заказ
    path: '/history/:id',
    name: 'История заказ',
    component: () => import('../views/ProfileView.vue'),
  },
  {
    // Данные аккаунта. Общий компонент
    path: '/account',
    name: 'Мои данные',
    component: () => import('../views/MyAccount.vue'),
  },
  {
    path: '/product/:id',
    name: 'Товар',
    component: () => import('../components/Customer/ProductCard.vue'),
    props: true,
  },
  {
    path: '/farmer-product/:id',
    name: 'farmer-product',
    component: () => import('../views/Customer/FarmCardView.vue'),
    props: true,
  },
  {
    path: '/catalog/:id',
    name: 'catalog',
    component: () => import('../views/Customer/CustomerCatalog.vue'),
    props: true,
  },
  {
    path: '/verification-confirm',
    name: 'verification-confirm',
    component: () => import('../views/VerificationConfirmView.vue'),
    props: true,
  },
  {
    path: '/reductions',
    name: 'Торги на покупку',
    component: () => import('../views/Reductions/ReductionsView.vue'),
  },
  {
    path: '/create-reduction',
    name: 'Создание торга на покупку',
    component: () =>
      import('../views/Customer/CustomerCreateReductionView.vue'),
  },
  {
    path: '/reductions/:id',
    name: 'Конкретный торг на покупку',
    component: () => import('../views/Reductions/ReductionView.vue'),
  },
  {
    path: '/auctions',
    name: 'Торги на продажу',
    component: () => import('../views/Auctions/AuctionsView.vue'),
  },
  {
    path: '/create-auction',
    name: 'Создание торга на продажу',
    component: () => import('../views/Farmer/FarmerCreateАuctionView.vue'),
  },
  {
    path: '/auctions/:id',
    name: 'Торг на продажу',
    component: () => import('../views/Auctions/AuctionView.vue'),
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import('../views/RequestsView.vue'),
    props: true,
  },
  {
    path: '/requests/:id',
    name: 'request',
    component: () =>
      import('../views/Customer/CustomerRequestView/CustomerRequestView.vue'),
    props: true,
  },
  {
    path: '/response/:id',
    name: 'response',
    component: () => import('../views/FarmerResponse.vue'),
    props: true,
  },
  {
    path: '/response/:id/:productAnswerId',
    name: 'responseProductAnswer',
    component: () => import('../views/Farmer/ProductResponseView.vue'),
    props: true,
  },
  {
    path: '/create-request',
    name: 'create-request',
    component: () => import('../views/Customer/CustomerCreateRequestView.vue'),
    props: true,
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('../views/PartnersView.vue'),
    props: true,
  },
  {
    path: '/partners/:id',
    name: 'partner',
    component: () => import('../views/PartnerView.vue'),
    props: true,
  },
  // -- Админка --
  {
    path: '/admin/users',
    name: 'Пользователи',
    component: () => import('../views/Admin/UserListView.vue'),
    meta: {
      layout: 'admin',
    },
  },
  {
    path: '/admin/users/:id',
    name: 'Пользователь',
    component: () => import('../views/Admin/UserView/UserView.vue'),
    meta: {
      layout: 'admin',
    },
  },
  {
    path: '/admin/orders',
    name: 'Заказы',
    component: () => import('../views/Admin/OrderListView.vue'),
    meta: {
      layout: 'admin',
    },
  },
  {
    path: '/admin/orders/:id',
    name: 'Заказ',
    component: () => import('../views/Admin/OrderView/OrderInfo.vue'),
    meta: {
      layout: 'admin',
    },
  },
  // Ошибки
  {
    path: '/mail-used',
    name: 'mailUsed',
    component: () => import('../views/Errors/MailUsed.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/error-occured',
    name: 'errorOccured',
    component: () => import('../views/Errors/ErrorOccured.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/no-company',
    name: 'noCompany',
    component: () => import('../views/Errors/NoCompany.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/invite-expired',
    name: 'inviteExpired',
    component: () => import('../views/Errors/InviteExpired.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/requests/:requestId/:recipientId',
    name: 'requestOrderComposition',
    component: () =>
      import(
        '../views/Customer/RequestOrderComposition/RequestOrderCompositionView.vue'
      ),
  },
  {
    path: '/create-order',
    name: 'createOrder',
    component: () =>
      import('../views/Customer/CreateOrder/CreateOrderView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const startRoutesNames = [
  'Регистрация',
  'Авторизация',
  'Сброс пароля',
  'Подтверждение почты',
]

const checkCoreCookie = () => {
  if (!getCoreCookie()) {
    logout()
  }
}

const checkUnLogin = async (to: RouteLocationNormalized) => {
  if (
    !getCoreCookie() &&
    to.fullPath !== '/sign-in' &&
    to.fullPath !== '/confirm-email' &&
    to.fullPath !== '/mail-used' &&
    to.fullPath !== '/error-occured' &&
    to.fullPath !== '/no-company' &&
    to.fullPath !== '/invite-expired' &&
    to.path !== '/invite-register' &&
    to.fullPath !== '/invite-register'
  ) {
    to.fullPath = '/sign-in'
    await router.push('/sign-in')
  }
}

const logout = () => {
  localStorage.clear()
  clearCookies()
}

const checkUMRTTokenISAlive = (to: RouteLocationNormalized) => {
  const umrt = localStorage.getItem('umrt')
  const nowDate = new Date()
  if (umrt) {
    if (
      to.fullPath === '/sign-in' &&
      decipherJWT(umrt)?.alive_until > nowDate.toISOString() &&
      !isAdmin.value
    ) {
      router.push('/').then()
    }
  }
}

// const checkUMTRTokenDead = () => {
//   const umrt = localStorage.getItem('umrt')
//   const nowDate = new Date()
//   if (decipherJWT(umrt)?.alive_until <= nowDate.toISOString()) {
//     logout()
//   }
// }

const checkAdminTokenRules = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) => {
  const tokenRoles: Roles[] = getCoreCookie()?.roles ?? []

  if (!tokenRoles.includes(Roles.Admin) && to.meta.layout === 'admin') {
    return from.fullPath
  }
  if (tokenRoles.includes(Roles.Admin) && to.meta.layout !== 'admin') {
    return from.fullPath
  }
}

// const checkRegisterStep = () => {
//   if (
//     getCoreCookie() &&
//     getCoreCookie().roles.includes(Roles['user.confirmed'])
//   ) {
//     router.push('/register').then()
//   }
// }

router.beforeEach(async (to, from) => {
  // checkUMRTTokenISAlive(to)
  //checkCoreCookie()

  if (!startRoutesNames.includes(<string>to.name)) {
    // checkUMTRTokenDead()
    await checkUnLogin(to)
    // checkAdminTokenRules(to, from)
    // checkRegisterStep()

    if (getCoreCookie()) {
      cookies.set('preRegisterData', getCoreCookie())
    }
  }
})
router.beforeResolve((from, to) => {
  if (from.path.includes('cart') && to.path !== '/create-order') {
    const { setPreviousRoute } = useStore()
    setPreviousRoute(to.path)
  }
})
export default router
